// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

.page,
.post {
  margin-bottom: 2em;
  
  li + li {
    margin-top: 0.25rem;
  }
}

// Blog post or page title
.page-title,
.post-title {
  color: var(--heading-color);
}

.page-title,
.post-title {
  margin-top: 0;
}

.post-title a {
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -0.5rem;
  margin-bottom: var(--spacer);
  color: var(--gray-600);
}

// Related posts
.related {
  padding-top: var(--spacer-2);
  padding-bottom: var(--spacer-2);
  margin-bottom: var(--spacer-2);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.related-posts {
  padding-left: 0;
  list-style: none;
  h3 {
    margin-top: 0;
  }
  a {
    text-decoration: none;
    small {
      color: var(--gray-600);
    }
  }
}
