// Layout
//
// Styles for managing the structural hierarchy of the site.
.container {
    max-width: 45rem;
    padding-left: var(--spacer-2);
    padding-right: var(--spacer-2);
    margin-left: auto;
    margin-right: auto;
}

footer {
    margin-top: var(--spacer-2);
    margin-bottom: var(--spacer-3);
}